import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import CheckMark from '../icons/CheckMark';
import XMark from '../icons/XMark';
import TryAgain from '../icons/TryAgain';
import correctAudio from '../media/correct.mp3';
import incorrectAudio from '../media/incorrect.mp3';

const Quiz = ({ quiz, id }) => { 
  // States
  const [quizScore, setQuizScore] = useState(0);
  const [quizResponses, setQuizResponses] = useState([]);
  const [reportOpen, setReportOpen] = useState(false);
  const [currQuestionId, setCurrQuestionId] = useState(0);
  const [optionClicked, setOptionClicked] = useState(-1);

  // Hooks
  const { i18n } = useTranslation();

  // Variables
  const abcd = ['A', 'B', 'C', 'D'];
  const correctMp3 = new Audio(correctAudio);
  const incorrectMp3 = new Audio(incorrectAudio);

  const resetQuizState = useCallback(() => {
    setQuizScore(0);
    setCurrQuestionId(0);
    setOptionClicked(-1);
    setQuizResponses([]);
    setReportOpen(false);
  }, []);

  /* TO DO: Make this better? */
  const quizResContent = {
    low: {
      img: 'https://gobituary-strapi-media.s3.amazonaws.com/thumbnail_low_5156cacf10.png',
      en_copy: 'A work in progress...',
      es_copy: 'Sí se puede!'
    },
    medium: {
      img: 'https://gobituary-strapi-media.s3.amazonaws.com/thumbnail_medium_af0bab7570.png',
      en_copy: 'Pretty good!',
      es_copy: 'Muy bien!'
    },
    high: {
      img: 'https://gobituary-strapi-media.s3.amazonaws.com/thumbnail_high_355e0f131d.png',
      en_copy: 'HISTORY WIZARD!',
      es_copy: 'Eres un mago!'
    }
  }

  useEffect(() => {
    // Reset quiz-related states when the id changes
    // (i.e. when the user navigates to a different grave)
    resetQuizState();
  }, [resetQuizState, id]);

    // Quiz Functions
  const handleQuizNextClick = () => {
    // Reset the option clicked to -1.
    setOptionClicked(-1);

    // Update the current question.
    setCurrQuestionId((previousId) => previousId + 1);
  }

  const handleQuizClick = (optionId, isCorrect) => {
    // Make some noiiiise.
    isCorrect ? correctMp3.play() : incorrectMp3.play();

    // Update which option was clicked.
    setOptionClicked(optionId);

    const updateResponses = [
      ...quizResponses,
      {
        correct: Boolean(isCorrect),
        inputId: optionId
      }
    ];

    // Update the quiz responses and score.
    setQuizResponses(updateResponses);
    setQuizScore(prevScore => prevScore + Number(isCorrect));

    // Wait until animation is finished to continue.
    setTimeout(() => {
      handleQuizNextClick();
    }, 2000)
  }

  const getLevel = () => {
    if (quizScore/quiz.length >= .7) return 'high';
    else if (quizScore/quiz.length >= .5) return 'medium';
    else return 'low';
  }

  return (
    <div className="c-grave__container ">
      <h3 className="h3"><Trans i18nKey="grave.checkYourKnowledge">Check your knowledge</Trans></h3>
      <div className="c-quiz u-position-relative">
        <h3 className="u-display-none">Quiz</h3>
        {/* Progress Bar */}
        <div className="c-quiz__progress-outer u-margin-bottom-32">
          { quiz.map((el, index) => {
            return (
              <div key={index}>
                { index < currQuestionId ?
                  (quizResponses[index].correct ? 
                    <div className="c-quiz__progress c-quiz__progress--check"><CheckMark className="c-quiz__progress-icon"/></div>
                    : <div className="c-quiz__progress c-quiz__progress--x"><span className="u-visually-hidden">Exit</span><XMark className="c-quiz__progress-icon"/></div>)
                  : <div className="c-quiz__progress c-quiz__progress--empty"></div>
                }
              </div>
          )})}
        </div>
        {
          currQuestionId < quiz.length ?
            Object.keys(quiz).map(id => {
              return (
                <div key={id} className={`c-quiz__question ${currQuestionId === parseInt(id) ? "is-active" : ""}`}>
                  <h3 className="u-font-weight-bold u-margin-bottom-24">{quiz[id][i18n.resolvedLanguage + "_question"]}</h3>
                  <ul className="c-quiz__options">
                    {Object.keys(quiz[id].options).map(optionId => {
                        return (
                          <li key={optionId}>
                            <button
                              disabled={optionClicked > -1}
                              className={`c-quiz__option-btn ${optionClicked > -1 ? "is-disabled" : ""} ${optionClicked > -1 && optionClicked === optionId ? "is-clicked" : ""} ${optionClicked > -1 && quiz[id].options[optionId].correct ? "correct a-enlarge" : "incorrect"} ${optionClicked > -1 && !quiz[id].options[optionId].correct && optionClicked !== optionId ? "fade-out" : "shift-up"}`}
                              onClick={() => handleQuizClick(optionId, quiz[id].options[optionId].correct)
                            }>
                              <div className="c-quiz__letter">{abcd[optionId]}</div>
                              <p className="u-margin-y-0 u-padding-x-24">{quiz[id].options[optionId][i18n.resolvedLanguage + "_option"]}</p>
                              <CheckMark className={`c-quiz__mark u-color-green ${optionClicked > -1 && quiz[id].options[optionId].correct ? "is-active" : ""}`}/>
                              <XMark className={`c-quiz__mark u-color-red ${optionClicked > -1 && optionClicked === optionId && !quiz[id].options[optionId].correct ? "is-active" : ""}`}/>
                            </button>
                          </li>
                        );
                      })
                    }
                  </ul>
                </div>
              );
            }) :
          <div>
            <img
              className="u-display-flex u-margin-0-auto"
              src={quizResContent[getLevel()].img}
              height={108}
              alt={quizResContent[getLevel()][i18n.resolvedLanguage + "_copy"]}
            />
            <p className="u-font-weight-bold u-text-align-center u-font-size-32 u-margin-bottom-16">{quizResContent[getLevel()][i18n.resolvedLanguage + "_copy"]}</p>
            <div className="c-quiz__score u-margin-bottom-32">
              <div className="u-margin-bottom-16">Score: {quizScore} / {quiz.length}</div>
              <div className="c-quiz__score-bar-container">
                <div className={`c-quiz__score-bar ${quizScore < quiz.length ? "a-score" : "a-perfect-score"}`} style={{'--bar-width': `${((quizScore/quiz.length) * 200 )}px`}}></div>
              </div>
            </div>
            <button className="c-button c-button--filled c-quiz__buttons" onClick={resetQuizState}><TryAgain className="c-quiz__restart-icon"/><Trans i18nKey="grave.tryAgain">Try Again</Trans></button>
            <button className="c-button c-quiz__buttons u-margin-top-16" onClick={() => { setReportOpen(prev => !prev) }}>
              { reportOpen ? <Trans i18nKey="grave.hideReport">Hide Report</Trans> : <Trans i18nKey="grave.viewReport">View Report</Trans> }
            </button>
            <div className={`c-quiz__report ${reportOpen ? "a-quiz-dropdown" : ""}`}>
              {Object.keys(quiz).map(id => {
                return (
                  <div key={id}>
                    <div>{[quiz[id][i18n.resolvedLanguage + "_question"]]}</div>
                    <ul className="c-quiz__options">
                      {Object.keys(quiz[id].options).map(optionId => {
                          return (
                            <li key={optionId} className="u-display-flex u-align-items-center u-position-relative">
                              <span className={`${quiz[id].options[optionId].correct ? "u-color-green u-font-weight-bold" : 
                                quizResponses[id].inputId === optionId && !quiz[id].options[optionId].correct ? "u-color-red u-font-weight-bold" : 
                                "u-color-dark-grey"
                              }`}>
                                {quiz[id].options[optionId][i18n.resolvedLanguage + "_option"]}
                              </span>
                              <CheckMark className={`c-quiz__mark u-color-green ${quiz[id].options[optionId].correct ? "is-active" : ""}`}/>
                              <XMark className={`c-quiz__mark u-color-red ${quizResponses[id].inputId === optionId && !quiz[id].options[optionId].correct ? "is-active" : ""}`}/>
                            </li>
                          );
                        })
                      }
                    </ul>
                  </div>
                );
              })}
            </div>
          </div> 
        }
      </div>
    </div>
  );
}

export default Quiz;