import { gql } from '@apollo/client';

const GRAVE = gql`
  query GetGrave($id: ID!) {
    grave(id: $id) {
      data {
        id,
        attributes {
          authors {
            data {
              id,
              attributes {
                name
              }
            }
          },
          reviewers {
            data {
              id,
              attributes {
                name
              }
            }
          },
          translators {
            data {
              id,
              attributes {
                name
              }
            }
          },          
          givenName,
          surname,
          en_headline,
          es_headline,
          wallpaper {
            data {
              attributes {
                url
                formats
                alternativeText
              }
            }
          },
          en_audio {
            data {
              attributes {
                url
              }
            }
          },
          es_audio {
            data {
              attributes {
                url
              }
            }
          },
          cemetery {
            data {
              attributes {
                name,
                lng,
                lat,
                featuredGraves {
                  data {
                    id,
                    attributes {
                      givenName,
                      surname,
                      lng,
                      lat,
                      en_headline,
                      es_headline,
                      avatar {
                        data {
                          attributes {
                            url
                            formats
                            alternativeText
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          avatar {
            data {
              attributes {
                url
                formats
                alternativeText
              }
            }
          },
          tapThrough {
            photo {
              data {
                attributes {
                  url,
                  formats
                  alternativeText
                }
              }
            },
            es_description,
            en_description,
            source
          }
          en_bio,
          es_bio,
          citations {
            text,
            link
          },
          eras {
            en_era,
            es_era,
            link
          },
          quiz {
            es_question,
            en_question,
            options {
              es_option,
              en_option,
              correct
            }
          }
        }
      }
    }
  }
`

export default GRAVE;