import { useTranslation, Trans } from 'react-i18next';

import AuthorLinks from '../components/AuthorLinks';

const Byline = ({ authors, reviewers, translators }) => {
	const { i18n } = useTranslation();

  return (
		<div className="u-margin-bottom-16 u-margin-top-0">
			{ authors.length > 0 ? 
				<p className="u-margin-bottom-8">
					<span><Trans i18nKey="grave.by">By</Trans> </span>
					<AuthorLinks authors={authors}/>
				</p>
			: null }

			{ reviewers.length > 0 ? 
				<p className="u-margin-bottom-8">
					<span><Trans i18nKey="grave.reviewedBy">Reviewed by</Trans> </span>
					<AuthorLinks authors={reviewers}/>
				</p>
			: null }

			{ translators.length > 0 && i18n.language === 'es' ? 
				<p>
					<span><Trans i18nKey="grave.translatedBy">Translated by</Trans> </span>
					<AuthorLinks authors={translators}/>
				</p>
			: null }
		</div>
	);
}

export default Byline;