import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          home: {
            welcomeTo: 'Welcome to',
            viewAllGraves: 'View all graves',
            graves: 'Graves',
            about: 'About the',
            cemeteryMap: 'Cemetery map',
            survey: 'Survey',
          },
          grave: {
            otherStories: 'Explore other stories',
            citations: 'Citations',
            learnMore: 'Learn more',
            hideReport: 'Hide Report',
            viewReport: 'View Report',
            tryAgain: 'Try Again',
            source: 'Source',
            fullStory: 'Read the full story',
            readTranscript: 'Read transcript',
            listenToThisStory: 'Listen to this story',
            readMore: 'Read more',
            readLess: 'Read less',
            surveyHeader: 'Help us measure our impact with a 5-minute survey',
            surveyCopy: 'Fill out our research survey to share if learning about Miami\'s history made you feel more connected and civically engaged today. Enter your email to receive the link.',
            submit: 'Submit',
            surveySuccess: 'Thank you! Check your email for the survey.',
            surveyFail: 'Sorry, we\'re having trouble submitting. Please try again later.',
            checkYourKnowledge: 'Check your knowledge',
            by: 'By',
            reviewedBy: 'Reviewed by',
            translatedBy: 'Translated by',
          },
          footer: {
            donate: 'Donate',
            about: 'About GoBituary',
          }
        }
      },
      es: {
        translation: {
          home: {
            welcomeTo: 'Bienvenidos a',
            viewAllGraves: 'Ver tumbas',
            graves: 'Tumbas',
            cemeteryMap: 'Mapa del cementerio',
            survey: 'Encuesta',
          },
          grave: {
            otherStories: 'Descubrir otras historias',
            citations: 'Referencias',
            learnMore: 'Aprender más',
            hideReport: 'Esconder informe',
            viewReport: 'Ver informe',
            tryAgain: 'Intentar de nuevo',
            source: 'Fuente',
            fullStory: 'Leer la historia completa',
            readTranscript: 'Leer transcripción',
            listenToThisStory: 'Escuchar la historia',
            readMore: 'Expandir',
            readLess: 'Cerrar',
            surveyHeader: 'Ayúdanos a medir nuestro impacto con una encuesta de 5 minutos',
            surveyCopy: 'Rellene nuestra encuesta de investigación para compartir si aprender sobre la historia de Miami le ha hecho sentirse más conectado y comprometido cívicamente en la actualidad. Introduzca su correo electrónico para recibir el enlace.',
            submit: 'Entregar',
            surveySuccess: 'Gracias! Revisa tu correo electrónico para recibir la encuesta.',
            surveyFail: 'Lo sentimos, estamos teniendo problemas para enviar. Inténtelo de nuevo más tarde.',
            checkYourKnowledge: 'Comprobar sus conocimientos',
            by: 'Por',
            reviewedBy: 'Revisado por',
            translatedBy: 'Traducido por',
            about: 'Sobre el',
          },
          footer: {
            donate: 'Donar',
            about: 'Nuestra historia',
          }
        }
      }
    }
  });

export default i18n;