import React, { useState, useCallback, useRef } from 'react';
import { useLoaderData } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

// Queries
import GRAVE from '../graphql/graveById';

// Components
import CemeteryMap from '../components/CemeteryMap';
import ClampedText from '../components/ClampedText';
import FeaturedCarousel from '../components/FeaturedCarousel';
import Loading from '../components/Loading';
import SiteHeader from '../components/SiteHeader';
import ProgressiveImage from '../components/ProgressiveImage';
import Blur from '../images/Blur.jpeg';
import EmailSurvey from '../components/EmailSurvey';
import SwingImage from '../components/SwingImage';
import Quiz from '../components/Quiz';
import Byline from '../components/Byline';

// Styles
import '../styles/grave.css';
import Popout from '../icons/Popout';
import SiteFooter from '../components/SiteFooter';
import AudioPlayer from '../components/AudioPlayer';
import ImageCarousel from '../components/ImageCarousel';
import Toast from '../components/Toast';

// Helpers
import addLineBreak from '../helpers/addLineBreak';
import Citations from '../components/Citations';

export default function FeaturedGravePage() {
  const id = useLoaderData();
  const { loading, error, data } = useQuery(GRAVE, { variables: { id: id } });

  // Hooks
  const { i18n } = useTranslation();
    
  // States
  const [bioTop, setBioTop] = useState(0);
  const [citationsTop, setCitationsTop] = useState(0);
  const [fontSizeClass, setFontSizeClass] = useState('u-font-size-16');

  // Refs
  const citationsRef = useRef(null);

  const updateCitationsTop = useCallback(() => {
    if (citationsRef.current !== null) {
      setCitationsTop(citationsRef.current.offsetTop);
    }
  } , []);
  
  const biographyRef = useCallback(node => {
    if (node !== null) {
      setBioTop(node.offsetTop);
      const resizeObserver = new ResizeObserver(() => { 
        // Update the position of citations top when bio is resized (for jump link)
        updateCitationsTop();
      });
      resizeObserver.observe(node);
    }
  }, [updateCitationsTop]);
  
  if (loading) return <div><SiteHeader/><Loading/><SiteFooter/></div>;
  if (error) return <div><SiteHeader/>Sorry! We can't seem to load the data.<SiteFooter/></div>

  const grave = data.grave.data.attributes;
  const graveId = data.grave.data.id;

  // Removes the current grave from the featured graves list.
  const featuredGraves = grave.cemetery.data.attributes.featuredGraves.data.filter(grave => grave.id !== id);

  return (
    <div className="c-grave">
      <SiteHeader/>
      
      {/* Intro */}
      <div className="c-grave__intro u-position-relative" style={{ borderBottom: `${grave.wallpaper.data ? '8px solid var(--gold)' : ''}` }}>
        {grave.wallpaper.data ? 
          <ProgressiveImage 
            className="c-grave__intro-bg" 
            src={`${grave.wallpaper.data.attributes.url}`}
            placeholderSrc={Blur}
          />
        : null}
        
        {grave.avatar.data ?
          <SwingImage id={id} imageUrl={grave.avatar.data.attributes.url}/>
        : null}

        <div className="u-display-flex u-justify-content-center">
          <div className="c-grave__headline u-text-align-center u-position-relative">
            <h1 className="c-grave__h1 u-font-size-20 u-text-align-center">{grave.givenName} {grave.surname}</h1>
            <h2 className="u-font-size-16 u-font-weight-normal u-margin-0">{grave[i18n.resolvedLanguage + "_headline"]}</h2>
          </div>
        </div>
      </div>

      { grave.tapThrough.length > 0 ? <ImageCarousel tapThrough={grave.tapThrough}/> : null }
      
      {/* Biography */}
      { grave.en_bio ?
        <div ref={biographyRef} className="c-grave__bio">
          <Byline authors={grave.authors.data} reviewers={grave.reviewers.data} translators={grave.translators.data}/>

          <div className="u-display-flex u-align-items-center u-justify-content-space-between u-margin-y-16">
            { grave.en_audio.data ? <AudioPlayer audioSrc={grave[i18n.resolvedLanguage + "_audio"].data.attributes.url}/> : null }
            
            <div className="c-font__toggle">
              <div className="c-font__container" onClick={() => setFontSizeClass('u-font-size-16')}>
                <button className={`c-font__button small ${fontSizeClass === 'u-font-size-16' ? 'active' : ''}`}
                >A</button>
              </div>
              <div className="c-font__container" onClick={() => setFontSizeClass("u-font-size-20")}>
                <button className={`c-font__button large ${fontSizeClass === 'u-font-size-20' ? 'active' : ''}`}
                >A</button>
              </div>
            </div>
          </div>

          <ClampedText 
            scrollTop={bioTop} 
            className="u-line-break" 
            text={addLineBreak(grave[i18n.resolvedLanguage + "_bio"])} 
            maxLines={25} 
            buttonClass="c-button c-button--filled"
            textClass={fontSizeClass}
          />

          <Link
            className="c-link"
            onClick={() => window.scrollTo({
              top: citationsTop + 10,
              behavior: "smooth"
            })}
          >
            <Trans i18nKey="grave.citations">Citations</Trans>
          </Link>
        </div>
      : null }

      { grave.quiz.length > 0 ? 
        <Quiz quiz={grave.quiz} id={id}/>
      : null }

      { grave.eras.length > 0 ? 
        <div className="c-grave__container">
          <h3 className="h3"><Trans i18nKey="grave.learnMore">Learn more</Trans></h3>
          <div>
            { grave.eras.map((el, index) =>
              <a href={el.link} className="c-link--button c-grave__era-pill" key={index} target="_blank" rel="noreferrer">
                {el[i18n.resolvedLanguage + "_era"]}
                <Popout className="c-grave__era-icon"/>
              </a>
            ) }
          </div>
        </div>
      : null }

      <div className="c-grave__container">
        <h3 className="h3"><Trans i18nKey="grave.otherStories">Explore other stories</Trans></h3>
        <FeaturedCarousel featured={featuredGraves}/>
      </div>

      <div ref={citationsRef}>
        { grave.citations ? <Citations className="c-grave__container" citations={grave.citations}/> : null }
      </div>

      <div className="c-grave__container">
        <EmailSurvey/>
      </div>

      <div className="c-grave__container"><h3 className="h3"><Trans i18nKey="home.cemeteryMap">Cemetery map</Trans></h3></div>
      <CemeteryMap cemetery={grave.cemetery.data.attributes} currGrave={true} referrer="grave"/>

      <SiteFooter/>

      <Toast cemetery={grave.cemetery.data.attributes} currGrave={graveId} referrer="grave"/>
    </div>
  )
}
