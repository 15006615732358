import React, { useState, useCallback } from 'react';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import { useTranslation } from 'react-i18next';

import Loading from '../components/Loading';
import MarkerWithInfoWindow from './MarkerWithInfoWindow';

import '../styles/cemetery-map.css';

const CemeteryMap = ({ cemetery, currGrave, referrer }) => {
  const { i18n } = useTranslation();
  const [activeMarker, setActiveMarker] = useState(null); // Track the active marker

  const handleMarkerClick = useCallback((markerId) => {
    setActiveMarker((prevMarker) => (prevMarker === markerId ? null : markerId));
  }, []);

  if (!cemetery) return <div><Loading/></div>;

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <Map
        className="c-cemetery-map"
        defaultCenter={{lat: cemetery.lat, lng: cemetery.lng}}
        defaultZoom={17}
        gestureHandling={'greedy'}
        disableDefaultUI={true}
        mapId={process.env.REACT_APP_GOOGLE_MAPS_MAP_ID}
      >
        {cemetery.featuredGraves.data.map(grave => {
          if (grave.attributes.lat) 
            return <MarkerWithInfoWindow 
              key={grave.id} 
              position={{ lat: grave.attributes.lat, lng: grave.attributes.lng }} 
              name={`${grave.attributes.givenName} ${grave.attributes.surname}`}
              headline={grave.attributes[i18n.language + "_headline"]}
              isActive={activeMarker === grave.id} // Pass active state
              onMarkerClick={() => handleMarkerClick(grave.id)} // Handle marker click
              isCurrGrave={currGrave === grave.id}
              graveId={grave.id}
              referrer={referrer}
            />
          return null;
        })}
      </Map>
    </APIProvider>
  );
};

export default CemeteryMap;