import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useTranslation, Trans } from 'react-i18next';

import '../styles/email.css';

import Spinner from './Spinner';
import CheckMark from '../icons/CheckMark';
import XMark from '../icons/XMark';

const EmailSurvey = ({ className }) => {
  const form = useRef();
  const { i18n } = useTranslation();

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    setLoading(true);

    emailjs
      .sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, `survey_template_${i18n.resolvedLanguage}`, form.current, {
        publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      })
      .then(
        () => {
          console.log('Success');
          
          // Briefly show a loader.          
          setTimeout(() => {
            setLoading(false);
            setFormSubmitted(true);
            setSuccess(true);
          }, 1000)
        },
        (error) => {
          console.log('Failed:', error.text);

          // Briefly show a loader.          
          setTimeout(() => {
            setLoading(false);
            setFormSubmitted(true);
            setSuccess(false);
          }, 1000)
        },
      );
  };

  return (
    <div className={className}>
      <h3 className="h3"><Trans i18nKey="grave.surveyHeader">Help us measure our impact with a 5-minute survey</Trans></h3>
      <p><Trans i18nKey="grave.surveyCopy">Fill out our survey to share if learning Miami's history made you feel more connected and civically engaged today.</Trans></p>
      
      {
        loading ?
          <Spinner className="u-margin-0-auto"/> 
          : 
          ( formSubmitted ?
            (
              success ? 
                <div>
                  <div className="c-email__check c-email__check--success u-margin-0-auto u-display-flex u-align-items-center u-justify-content-center"><CheckMark className="c-email__check-icon--success"/></div>
                  <p className="u-font-weight-bold u-text-align-center"><Trans i18nKey="grave.surveySuccess">Thank you! Check your email for the survey.</Trans></p>
                </div>
              :
                <div>
                  <div className="c-email__check c-email__check--fail u-margin-0-auto u-display-flex u-align-items-center u-justify-content-center"><XMark className="c-email__check-icon--fail"/></div>
                  <p className="u-font-weight-bold u-text-align-center"><Trans i18nKey="grave.surveyFail">Sorry, we're having trouble submitting. Please try again later.</Trans></p>
                </div>
            )
          : 
            <form ref={form} onSubmit={sendEmail} className="c-email u-text-align-center">
              <input className="c-email__input u-margin-bottom-16" type="email" name="user_email" placeholder="Email"/>
              <input className="c-button c-button--filled u-margin-0-auto" type="submit" value={i18n.t("grave.submit")}/>
            </form>
          )
      }
    </div>
  );
};

export default EmailSurvey;