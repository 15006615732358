import React, { useState, useEffect } from 'react';
import XMark from '../icons/XMark';
import { Trans } from 'react-i18next';

import EmailSurvey from './EmailSurvey';
import CemeteryMap from './CemeteryMap';

import Clipboard from '../icons/Clipboard';
// import Map from '../icons/Map';

import '../styles/toast.css';

const Toast = (props) => {
  const [popupActive, setPopupActive] = useState(false);
  const [popupContent, setPopupContent] = useState("");

  const content = {
    "survey": <EmailSurvey className="c-popup__survey"/>,
    "map": <CemeteryMap cemetery={props.cemetery} currGrave={props.currGrave ? props.currGrave : null} referrer={props.referrer}/>
  }

  useEffect(() => {
    return () => (setPopupActive(false)); // Cleanup on unmount
  }, []);

  return (
    <div>
      <div className={`c-popup ${popupActive ? 'is-active' : ''}`}>
        <div className="c-popup__inner u-position-relative">
          <button className="c-popup__exit" onClick={() => setPopupActive(false)}>
            <span className="u-visually-hidden">Exit</span>
            <XMark className="u-color-black"/>
          </button>
          {content[popupContent]}
        </div>
      </div>

      <div className={`c-toast__bar ${popupActive ? 'is-hidden' : 'is-active'} u-display-flex u-gap-8`}>
        {/* <button 
          className="u-display-flex u-align-items-center u-flex-direction-column u-background-none u-border-none"
          onClick={() => {
            setPopupActive(true)
            setPopupContent("map")
          }}
        >
          <div className="c-toast__btn u-display-flex u-align-items-center u-justify-content-center">
            <Map className="c-toast__btn-icon"/>
          </div>
          <span className="u-color-black u-font-size-14 u-text-decoration-underline">Map</span>
        </button> */}

        <button 
          className="u-display-flex u-align-items-center u-flex-direction-column u-background-none u-border-none"
          onClick={() => {
            setPopupActive(true)
            setPopupContent("survey")
          }}
        >
          <div className="c-toast__btn u-display-flex u-align-items-center u-justify-content-center">
            <Clipboard className="c-toast__btn-icon"/>
          </div>
          <span className="u-color-black u-font-size-14 u-text-decoration-underline"><Trans i18nKey="home.survey">Survey</Trans></span>
        </button>
      </div>
    </div>
  );
};

export default Toast;