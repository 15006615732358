import React from 'react';
import { Trans } from 'react-i18next';

import '../styles/site-footer.css';

import GobituaryGraveWhite from '../icons/GobituaryGraveWhite';

export default function SiteFooter() {
  return (
    <nav className="c-site-footer u-display-flex u-flex-direction-column">
      <div className="u-margin-0-auto"><GobituaryGraveWhite className="u-margin-bottom-16"/></div>
      <div className="c-site-footer__section c-site-footer__about u-display-flex u-align-items-center u-justify-content-center u-flex-direction-column">
        <a className="u-color-white" href="https://floridainnovation.org/gobituary/"><Trans i18nKey="footer.about">About GoBituary</Trans></a>
        <a className="u-color-white" href="https://www.classy.org/give/497194/#!/donation/checkout"><Trans i18nKey="footer.donate">Donate</Trans></a>
      </div>
      <div className="c-site-footer__footnote">
        <div><span className="u-color-white">Powered by </span><a className="u-color-white" href="https://floridainnovation.org/">Florida Community Innovation</a></div>
      </div>
    </nav>
  )
}
