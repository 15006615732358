import { gql } from '@apollo/client';

const CEMETERY = gql`
  query GetCemeteryByID($id: ID!) {
    cemetery(id: $id) {
      data {
        id
        attributes {
          name,
          lat,
          lng,
          hero {
            data {
              attributes {
                url
                formats
              }
            }
          },
          authors {
            data {
              id,
              attributes {
                name
              }
            }
          },
          reviewers {
            data {
              id,
              attributes {
                name
              }
            }
          },
          translators {
            data {
              id,
              attributes {
                name
              }
            }
          },
          graves {
            data {
              id,
              attributes {
                givenName,
                surname,
                lng,
                lat,
                en_headline,
                es_headline,
                avatar {
                  data {
                    attributes {
                      url
                      formats
                    }
                  }
                }
              }
            }
          }
          featuredGraves {
            data {
              id,
              attributes {
                givenName,
                surname,
                lng,
                lat,
                en_headline,
                es_headline,
                avatar {
                  data {
                    attributes {
                      url,
                      formats
                      alternativeText
                    }
                  }
                }
              }
            }
          }
          en_sections {
            text
            heading
          }
          es_sections {
            text
            heading
          }
          citations {
            link
            text
          }
        }
      }
    }
  }
`

export default CEMETERY;